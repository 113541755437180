
import { useDispatch, useSelector } from 'react-redux'

import { Link, useParams, useSearchParams } from "react-router-dom";
import { addNotification } from "stores/notificationsStore/notificationsStoreSlice";
import Select from 'react-select'
import { Pill } from 'components/pill';

import {
    ContentWrapper,
    Title,
    Table,
    TableRow,
    TableColumn,
    TableWrapper,
    LoadingWrapper,
    ModalContainer,
    TableBody,
} from 'views/views.styles'

import "react-sliding-pane/dist/react-sliding-pane.css";
import { useState, useEffect } from 'react';
import { activateContract, deactivateContract, getContract, getContractUsers, updateContract } from 'services/account-service/contractServices';
import { useAuth0 } from "@auth0/auth0-react";

import { Contract, Restriction, User } from 'typings';
import { Modal } from 'components/modal';
import { Loader } from 'components/loader';
import { Users } from 'views/users';
import { getRestrictions } from 'services/account-service/restrictionsServices';
import { Button } from 'components/button';
import { FieldError, Input } from 'components/input/input';
import { InputSwitch } from 'components/inputSwitch';
import { RootState } from 'store';

export const ContractInfo = () => {

    const scopes = useSelector((state: RootState) => state.userStore.scopes)
    const { account_id, contract_id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const isEditMode = searchParams.get('edit') === 'true' && scopes?.includes("accounts:contracts:write"); // Check if 'edit' param is present and set to 'true'

    const dispatch = useDispatch();
    const [showLoading, setShowLoading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [refreshTrigger, setRefreshTrigger] = useState(0);


    /*
    Might help to get the account name, but it is not necessarily loaded if entering to this page by URL directly
    const accounts = useSelector((state: RootState) => state.userStore.accounts)
    const [account, setAccount] = useState<Account | undefined>(undefined);
    */

    const [contract, setContract] = useState<Contract | undefined>(undefined);
    //const [contractRestrictionValues, setContractRestrictionValues] = useState<{ [key: string]: string }>({}); // Holds the actual restrictions for the account, with id and label

    const [restrictionOptions, setRestrictionOptions] = useState<any[] | undefined>(undefined);
    const [collectionOptions,] = useState<any[] | undefined>(  // TODO: Get the actual collections from the STAC API
        [
            { value: "RR", label: "Rapid Response" },
            { value: "l1c-benchmark", label: "l1c-benchmark" },
            { value: "quickview-visual-thumb", label: "QuickView Visual Thumb" },
            { value: "quickview-visual", label: "QuickView Visual" },
            { value: "quickview-toa", label: "QuickView TOA" },
            { value: "l1c", label: "L1C" },
            { value: "hsi", label: "HSI" },
            { value: "644a97fe4d6e6223baccf333", label: "644a97fe4d6e6223baccf333" },
            { value: "quickview-toa-thumb", label: "QuickView TOA Thumb" },
            { value: "L1D_FROM_L1C", label: "L1D_FROM_L1C" },
            { value: "l1d", label: "L1D" },
            { value: "l1d-sr", label: "L1D_SR" },
            { value: "l0", label: "L0" },
            { value: "l1d-fast", label: "L1d Fast" },
            { value: "l1d-fast-sr", label: "L1d Fast SR" },
            { value: "l1a", label: "L1A" },
            { value: "l1d-sr-fast", label: "l1d-sr-fast" },
            { value: "gaia", label: "Gaia" }
        ]
    );

    const [contractUsers, setContractUsers] = useState<User[] | undefined>(undefined);
    const [errors, setErrors] = useState<{ [key: string]: FieldError }>({});

    const [formData, setFormData] = useState<Contract>({
        name: '',
        status: undefined,
        archive_configuration: {
            collections: [],
            restrictions: []
        },
        tasking_configuration: {},
    });

    //Get all the system available restrictions so we can populate the restrictions select menu.    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = await getAccessTokenSilently();
                getRestrictions(token,
                    (response) => {
                        const restrictions = response.data.results;
                        const sorted = [...restrictions].sort();
                        const values = sorted.map((item: Restriction) => ({
                            value: item.restriction_id,
                            label: item.name
                        }))
                        setRestrictionOptions(values);
                    },
                    (error) => {
                        console.error('Error reading restrictions:', error.message);
                        dispatch(addNotification({ type: 'Error reading restrictions', description: error.response.data.details }));
                    }
                );
            } catch (error: any) {
                console.error('Error reading restrictions:', error.message);
                dispatch(addNotification({ type: 'Error reading restrictions', description: error.response.data.details }));
            }
        };

        fetchData();
    }, [getAccessTokenSilently, dispatch])



    // Main fetch for contract information
    useEffect(() => {
        const loadContractInfo = async () => {
            setShowLoading(true);
            const token = await getAccessTokenSilently();
            if (account_id && contract_id) {
                getContract(token, account_id, contract_id,
                    (response) => {

                        const contract: Contract = response.data;
                        setContract(contract);  // This is the main object that holds all the information., restrictions are ids, not the label

                        //const restriction_ids = contract?.archive_configuration?.restrictions
                        //setRestrictrionsValues(restriction_ids?.map(id => { return { value: id, label: id } }));                                                
                        //console.log("Contract fetched", contract)
                        
                        // This is to remove that "has credentials" and initialize it as a blank string.
                        const modified_contract = {
                            ...contract,
                            tasking_configuration: ""
                        }
                        setFormData(modified_contract);
                        getContractUsers(token, account_id, contract_id,
                            (response) => {
                                setContractUsers(response.data.results);
                            },
                            (error) => {
                                dispatch(
                                    addNotification({
                                        type: "error",
                                        description: error.response.data.details,
                                    })
                                );
                            },
                        )
                        setShowLoading(false);
                    },
                    (error) => {
                        dispatch(
                            addNotification({
                                type: "error",
                                description: error.response.data.details,
                            })
                        );
                    },
                );
            }
        }

        loadContractInfo();
    }, [account_id, contract_id, dispatch, getAccessTokenSilently, refreshTrigger])


    const handleSubmit = async () => {

        const token = await getAccessTokenSilently();
                
        const transformedData = {
            name: formData.name,
            archive_configuration: formData.archive_configuration,
            status: formData.status,
            ...(formData.tasking_configuration !=="" ? {
                tasking_configuration: {
                    credentials: {
                        "blackwing": "Token " + formData.tasking_configuration,
                    }
                }
            } : {}),
        };
        
        if (account_id && contract_id) {
            try {
                setShowLoading(true);
                await updateContract(token, account_id, contract_id, transformedData)

                try {
                    if (contract?.status === "ACTIVE" && transformedData.status === "INACTIVE") await deactivateContract(token, account_id, contract_id);
                    if (contract?.status === "INACTIVE" && transformedData.status === "ACTIVE") await activateContract(token, account_id, contract_id);
                    dispatch(addNotification({ type: 'success', description: "Contract status updated succesfully" }));
                } catch (error: any) {
                    console.log(error)
                    dispatch(addNotification({ type: 'error', description: "Error updating contract status: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors), }));
                }

                dispatch(addNotification({ type: 'success', description: "Contract updated succesfully" }));

                setSearchParams({}); // Remove the 'edit' parameter to exit edit mode
                setRefreshTrigger((prev) => prev + 1);
            } catch (error: any) {
                console.log(error)
                dispatch(addNotification({ type: 'error', description: "Error updating: " + error.response?.data?.message + " " + JSON.stringify(error.response?.data?.errors) || 'Failed to update contract', }));

            } finally {
                setShowLoading(false);
            }
        }
    }


    // Handle input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (isEditMode) {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    // Handle cancel action
    const handleCancel = () => {
        setSearchParams({}); // Remove the 'edit' parameter to exit edit mode                
        if (!!contract) {
            // This is to remove that "has credentials" and initialize it as a blank string.
            const modified_contract = {
                ...contract,
                tasking_configuration: ""
            }
            setFormData(modified_contract);            
        }
    };


    // Toggle edit mode by modifying query parameters
    const toggleEditMode = () => {

        if (isEditMode) {
            setSearchParams({}); // Remove 'edit' parameter to exit edit mode            
        } else {
            setSearchParams({ edit: 'true' }); // Add 'edit=true' to enter edit mode            

            if (!!contract) {
                // This is to remove that "has credentials" and initialize it as a blank string.
                const modified_contract = {
                    ...contract,
                    tasking_configuration: ""
                }
                setFormData(modified_contract)
            }
        }
    };

    // Handle switch change separately
    const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.checked?"ACTIVE":"INACTIVE",
        });
        return e.target.value === "on"
    };
    
    const handleCollectionsChange = (data: any) => {
        setFormData({
            ...formData,
            archive_configuration: {
                ...formData.archive_configuration,
                collections: data.map((x: { value: string }) => x.value)
            }
        });
    }

    const handleRestrictionsChange = (data: any) => {
        setFormData({
            ...formData,
            archive_configuration: {
                ...formData.archive_configuration,
                restrictions: data.map((x: any) => x.value)
            }
        });
    }

    return (
        <>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
                <Link to={`/contracts/${account_id}`}>← Back to contracts</Link>
                <ContentWrapper full>
                    <Title>
                        Properties
                    </Title>
                    <ContentWrapper full>
                        <TableWrapper>
                            <Table>
                                <TableBody>
                                    <TableRow>
                                        <TableColumn width={"100px"}><b>Contract name</b></TableColumn>
                                        <TableColumn>
                                            {!isEditMode ? (
                                                contract?.name
                                            ) : (
                                                <Input
                                                    minLength={3}
                                                    required
                                                    name={'name'}
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    helperText='Name for the restriction'
                                                    onValidate={(error) => {
                                                        setErrors((prevErrors) => ({ ...prevErrors, name: error }))
                                                    }}
                                                />
                                            )}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn width={"100px"}><b>Contract id</b></TableColumn>
                                        <TableColumn>{contract?.contract_id}</TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn width={"100px"}><b>Contract account</b></TableColumn>
                                        <TableColumn><Link to={`/accounts/${account_id}`}>{account_id}</Link></TableColumn> {/* FIXME: Get the name of the account somehow */}
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn width={'100px'}>
                                            <b>Status</b>
                                        </TableColumn>
                                        <TableColumn>
                                            {isEditMode ? (
                                                <InputSwitch
                                                    checked={formData.status==="ACTIVE"}
                                                    name="status"
                                                    small
                                                    onChange={handleSwitchChange}
                                                />
                                            ) : (
                                                contract?.status
                                            )}
                                        </TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn width={"100px"}><b>Created</b></TableColumn>
                                        <TableColumn>{contract?.created_at}</TableColumn>
                                    </TableRow>
                                    <TableRow>
                                        <TableColumn width={"100px"}><b>Last modified</b></TableColumn>
                                        <TableColumn>{contract?.updated_at}</TableColumn>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableWrapper>
                    </ContentWrapper>
                </ContentWrapper>

                <ContentWrapper full>
                    <Title>
                        Archive configuration
                    </Title>
                    <ContentWrapper full>
                        <TableWrapper>
                            <Table style={{ tableLayout: "fixed" }}>
                                <TableBody>
                                    <TableRow>
                                        <TableColumn width={"100px"}>
                                            <b>Collections</b>
                                        </TableColumn>
                                        <TableColumn>

                                            {isEditMode ?
                                                <Select
                                                    isSearchable
                                                    value={formData.archive_configuration?.collections.map((collection: string) => {
                                                        return {
                                                            value: collection,
                                                            label: collection
                                                        }
                                                    })}
                                                    options={collectionOptions}
                                                    onChange={(data) => {
                                                        handleCollectionsChange(data);
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999995 }),
                                                        multiValueRemove: base => ({ ...base })
                                                    }}
                                                    isMulti
                                                    placeholder={"Select collections"}
                                                />
                                                :
                                                <div>
                                                    {contract?.archive_configuration?.collections ?
                                                        contract?.archive_configuration?.collections?.sort().map((collection: string) => <Pill label={collection}></Pill>)
                                                        :
                                                        "No collections configured"
                                                    }
                                                </div>
                                            }

                                        </TableColumn>
                                    </TableRow>

                                    <TableRow>
                                        <TableColumn>
                                            <b>Restrictions</b>
                                        </TableColumn>
                                        <TableColumn>

                                            {isEditMode ?
                                                <Select
                                                    isSearchable
                                                    value={formData.archive_configuration?.restrictions?.map((restrictionId: any) => {
                                                        return restrictionOptions?.find((restriction) => restriction.value === restrictionId);
                                                    })}

                                                    options={restrictionOptions}
                                                    onChange={(data) => {
                                                        handleRestrictionsChange(data);
                                                    }}
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999995 }),
                                                        multiValueRemove: base => ({ ...base })
                                                    }}
                                                    isMulti
                                                    placeholder={"No collections assigned"}
                                                />
                                                :
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ display: "flex" }}>
                                                        {contract?.archive_configuration?.restrictions ?
                                                            contract?.archive_configuration?.restrictions?.map((restrictionId: any) => {
                                                                const restriction = restrictionOptions?.find((restriction) => restriction.value === restrictionId);
                                                                if (restriction) {
                                                                    return (
                                                                        <Button
                                                                            key={`${restriction.value}-${restriction.label}`}
                                                                            secondary
                                                                            size='xs'
                                                                            text={restriction.label}
                                                                            to={`/restrictions/${restriction.value}`}
                                                                        />
                                                                    );
                                                                }
                                                                return null; // Explicitly return null when restriction is not found
                                                            }) :
                                                            "No restrictions configured"
                                                        }
                                                    </div>
                                                </div>
                                            }

                                        </TableColumn>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableWrapper>

                    </ContentWrapper>

                </ContentWrapper>

                <ContentWrapper full>
                    <Title>
                        Tasking configuration
                    </Title>
                    <ContentWrapper full>
                        <TableWrapper>
                            <Table style={{ tableLayout: "fixed" }}>
                                <TableBody>
                                    <TableRow>
                                        <TableColumn width={"100px"}>
                                            <b>Credentials</b>
                                        </TableColumn>
                                        <TableColumn width={"100%"}>
                                            {isEditMode ? (
                                                <Input
                                                    type="text"
                                                    name="tasking_configuration" //XXX: Abusing the name here, because the type for reding and writing is different.
                                                    helperText='This will overwrite any credential already stored'
                                                    small
                                                    onChange={handleInputChange}
                                                />
                                            ) : (
                                                contract?.tasking_configuration && Object.keys(contract?.tasking_configuration).includes('has_credentials') ? "Has credentials configured" : "Does not have credentials configured"
                                            )}
                                        </TableColumn>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableWrapper>
                    </ContentWrapper>
                </ContentWrapper>

                <div style={{ display: 'flex', gap: '10px', margin: '10px' }}>
                    {!isEditMode && scopes?.includes("accounts:contracts:write") ? (
                        <Button
                            size="sm"
                            text="Edit"
                            onClick={toggleEditMode}
                        />
                    ) : (
                        isEditMode &&
                        <>
                            <Button
                                size="sm"
                                text="Save"
                                onClick={handleSubmit}
                                disabled={!Object.values(errors).every((fieldError) => fieldError.isValid)}
                            />
                            <Button
                                size="sm"
                                text="Cancel"
                                onClick={handleCancel}
                            />
                        </>
                    )}
                </div>

                <ContentWrapper full>
                    <div style={{ height: "500px" }}>
                        <Users fixedUsers={contractUsers} />
                    </div>
                </ContentWrapper>

            </div>

            <Modal isShown={showLoading}>
                <ModalContainer>
                    <LoadingWrapper>
                        <Loader text={"Loading data..."} />
                    </LoadingWrapper>
                </ModalContainer>
            </Modal>
        </>
    )
}
